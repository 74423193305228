<template>
	<div>
		
	</div>
</template>

<script>
	//引入状态管理
	import {mapState} from 'vuex'
	export default {
		data() {
			return {
				uid:"",//团号
			}
		},
		mounted: function() {
			var  _this = this
			if(this.isLogin){
				if(this.$route.query&&this.$route.query.uid){
					//团号
					this.uid = this.$route.query.uid
					this.getDetailPage()
				}else{
					this.$util.routerPath("/404")
				}
			}else{
				this.$alert('请先登录采购商账号~', '温馨提示', {
				    confirmButtonText: '确定',
				    callback: action => {
				       _this.$router.push({
				       	 name: "home"
				       });
				    }
				});
			}
		},
		computed: {
			...mapState(['uuid','isLogin','phone']),
		},
		methods: {
			getDetailPage(){
				var _this = this
				var params = {}
				params["groupNo"] = this.uid //父级订单号
				params["userUuid"] = this.uuid
				//console.log(params)
				this.$http.post('medicine/medicineDrugsGroup/GroupNoToMedicineGroup', params).then(function(res) {
					//数据处理
					if (res.code == 200) {
						var dataobj = res.data
						var carts = []
						var skuobj = {}
						skuobj["skuImg"] = dataobj.medicineDrugs.mainImages[0]
						skuobj["goodsUuid"] = dataobj.goodsUuid 
						skuobj["goodsobj"] = dataobj
						skuobj["goodsNum"] = dataobj.goodsNum || 1
						skuobj["goodsPirce"] = dataobj.price
						carts.push(skuobj)
						//立即购买
						var orderList = [{
							logo: "/static/logo.png",
							storeName:_this.bsnName,
							goodsList:carts,
							physical:1,//虚实物 0虚物 1实物
							remarks:"",//订单备注
						}]
						localStorage.setItem("orderList",JSON.stringify(orderList))
						_this.$router.push({ path: '/payPage', query: {ori:'team',groupType:1,groupNo:_this.uid}})
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
		}
	}
</script>

<style>

</style>
